
"use strict";

import * as $ from "jquery";
import "popper.js/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Since MDBootstrap version 4.9, importing mdb.js will fail with Webpack compile
// We need to import from MDB Source instead
import "./import-mdb";

// The Order here is very important
// JQuery Validation Unobtrusive MUST come after MDB, or we get an error and Client side validation does not work
import "jquery-validation-unobtrusive";

// Our Sass file
import "../sass/caringbreathe.scss";

// Use FontAwesome JS - All of it
import "@fortawesome/fontawesome-free/js/all.min.js";

// Other TypeScript functions
import { updateUnobtrusiveValidation, setRedAsterisk } from "./util/general-validation";

function generalSetup() {

	updateUnobtrusiveValidation();

	// We can disable or move it to page specific if we ever use Asterisk for something else
	setRedAsterisk();
}

$(document).ready(function () {

	generalSetup();	

	// Logout confirmation - message is on the _layout page
	// Use MDBootstrap Modal
	$("#ts-logout-yes").click(function () {
		$("#ts-logout-form").submit();
	});

	// show/hide password
	const $password = $('#ts-password');	
	const $reveal = $('#ts-reveal-password');
	const $confirmPassword = $('#ts-confirm-password');
	const $revealConfirm = $('#ts-reveal-confirm-password');
	const handleClick = function () {
		let type = $password.attr('type');
		type = type === 'password' ? 'text' : 'password';
		$password.attr('type', type);
		$reveal.toggleClass('active');
	};
	const handleClick2 = function () {
		let type = $confirmPassword.attr('type');
		type = type === 'password' ? 'text' : 'password';
		$confirmPassword.attr('type', type);
		$revealConfirm.toggleClass('active');
	};
	$reveal.on('click', handleClick.bind(this));
	$revealConfirm.on('click', handleClick2.bind(this));

	// Initialize MDB Select
	///@ts-ignore
	$(".mdb-select").materialSelect();
		
	// Set up text to speech only if we need it
	if ($("#ts-text-to-speech").length > 0) {
			import("./util/texttospeech").then(module => {
			const textToSpeech = module.textToSpeech;
			textToSpeech();
		});
	}

	// Set up Search Country
	if (document.querySelector("#ts-search-world-country") !== null) {
		import("./dropdown/search-country").then(module => {
			const setupSearchCountry = module.setupSearchCountry;
			setupSearchCountry();
		});
	}

	// Set up Search State
	if (document.querySelector("#ts-search-usa-state") !== null) {
		import("./dropdown/search-state").then(module => {
			const setupSearchState = module.setupSearchState;
			setupSearchState();
		});
	}

	// Set up Stop Watch
	if (document.querySelector("#ts-stop-watch") !== null) {
		import("./stopwatch/stopwatch").then(module => {
			const setupStopWatch = module.setupStopWatch;
			setupStopWatch();
		});
	}

	// Set up Daily Looong Chart
	if (document.querySelector("#ts-dailyloong-chart") !== null) {
		import("./charts/daily-looong-chart").then(module => {
			const setupDailyLooongChart = module.setupDailyLooongChart;
			setupDailyLooongChart();
		});
	}

	// Set up Daily Looong Time Chart
	if (document.querySelector("#ts-dailyloong-time-chart") !== null) {
		import("./charts/time-charts").then(module => {
			const setupDailyLooongTimeChart = module.setupDailyLooongTimeChart;
			setupDailyLooongTimeChart();
		});
	}

	// Remove the white background in body for index page
	if (document.querySelector("#ts-dark-blue-background") !== null) {
		document.body.style.backgroundColor = "#102a51";
	}


	// Prevent multiple submit button click
	$(document).on("submit", "form", function () {
		const buttons = $(this).find("[type='submit']");
		if ($(this).valid()) {
			buttons.each(function (btn) {
				$(buttons[btn]).prop("disabled", true);
			});
		} else {
			buttons.each(function (btn) {
				$(buttons[btn]).prop("disabled", false);
			});
		}
	});





	// End
});

