
"use strict";

function updateUnobtrusiveValidation() {	
	$("form").removeData("validator");
	$("form").removeData("unobtrusiveValidation");	
	$.validator.unobtrusive.parse("form");
}


function setRedAsterisk() {
	// If we want to replace the entire label with red font
	//$("label:contains("*")").addClass("warning-message-standard-font");
	//$("label:contains("*")").css("color", "red");

	// Otherwise this replaces only the * using regex
	const replacement = "*";

	// For Input Fields
	$("label:contains('" + replacement + "')").each(function () {
		// Regex is slower
		//$(this).html($(this).text().replace(/\*/gi, "<span class="warning-message-standard-font">" + replacement + "</span>"));
		$(this).html($(this).html().replace("*", "<span class='warning-message-standard-font'>" + replacement + "</span>"));
	});

	// For DropDown List
	$("span.filtrable:contains('" + replacement + "')").each(function () {
		// Regex is slower
		//$(this).html($(this).text().replace(/\*/gi, "<span class="warning-message-standard-font">" + replacement + "</span>"));
		$(this).html($(this).html().replace("*", "<span class='warning-message-standard-font'>" + replacement + "</span>"));
	});

}

export class StringUtils {
	public static isNullOrEmpty(val: string): boolean {
		if (val === undefined || val === null || val.trim() === '') {
			return true;
		}
		return false;
	};
}

export { updateUnobtrusiveValidation, setRedAsterisk };

