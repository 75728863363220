
// Since MDBootstrap version 4.9, importing mdb.js will fail with Webpack compile
// We need to import from MDB Source instead
// https://gist.github.com/PabloCamp0s/a22b810af012d6110e98cb7539e96484#file-material-design-bootstrap-js
import 'mdbootstrap-pro/src/js/free/forms-free';
import 'mdbootstrap-pro/src/js/free/scrolling-navbar';
import 'mdbootstrap-pro/src/js/free/treeview';
import 'mdbootstrap-pro/src/js/free/wow';
import 'mdbootstrap-pro/src/js/pro/buttons';
import 'mdbootstrap-pro/src/js/pro/cards';
import 'mdbootstrap-pro/src/js/pro/character-counter';
import 'mdbootstrap-pro/src/js/pro/chips';
import 'mdbootstrap-pro/src/js/pro/collapsible';
import 'mdbootstrap-pro/src/js/pro/dropdown/dropdown';
import 'mdbootstrap-pro/src/js/pro/dropdown/dropdown-searchable';
import 'mdbootstrap-pro/src/js/pro/file-input';
import 'mdbootstrap-pro/src/js/pro/material-select/material-select';
import 'mdbootstrap-pro/src/js/pro/material-select/material-select-view';
import 'mdbootstrap-pro/src/js/pro/material-select/material-select-view-renderer';
import 'mdbootstrap-pro/src/js/pro/mdb-autocomplete';
import 'mdbootstrap-pro/src/js/pro/picker';
import 'mdbootstrap-pro/src/js/pro/picker-date';
import 'mdbootstrap-pro/src/js/pro/picker-time';
import 'mdbootstrap-pro/src/js/pro/preloading';
import 'mdbootstrap-pro/src/js/pro/range-input';
import 'mdbootstrap-pro/src/js/pro/sidenav';
import 'mdbootstrap-pro/src/js/pro/sidenav2';
import 'mdbootstrap-pro/src/js/pro/smooth-scroll';
import 'mdbootstrap-pro/src/js/pro/sticky';
import 'mdbootstrap-pro/src/js/vendor/addons-pro/chat';
import 'mdbootstrap-pro/src/js/vendor/addons-pro/multi-range';
import 'mdbootstrap-pro/src/js/vendor/addons-pro/simple-charts';
import 'mdbootstrap-pro/src/js/vendor/addons-pro/steppers';
import 'mdbootstrap-pro/src/js/vendor/addons-pro/timeline';
import 'mdbootstrap-pro/src/js/vendor/addons/datatables';
import 'mdbootstrap-pro/src/js/vendor/addons/datatables-select';
import 'mdbootstrap-pro/src/js/vendor/addons/datatables-select2';
import 'mdbootstrap-pro/src/js/vendor/addons/datatables2';
import 'mdbootstrap-pro/src/js/vendor/addons/imagesloaded.pkgd.min';
import 'mdbootstrap-pro/src/js/vendor/addons/jquery.zmd.hierarchical-display';
import 'mdbootstrap-pro/src/js/vendor/addons/masonry.pkgd.min';
import 'mdbootstrap-pro/src/js/vendor/addons/rating';
import 'mdbootstrap-pro/src/js/vendor/free/chart';
import 'mdbootstrap-pro/src/js/vendor/free/chartjs-datalabels';
import 'mdbootstrap-pro/src/js/vendor/free/enhanced-modals';
import 'mdbootstrap-pro/src/js/vendor/free/jquery.easing';
import 'mdbootstrap-pro/src/js/vendor/free/velocity';
import 'mdbootstrap-pro/src/js/vendor/free/waves';
import 'mdbootstrap-pro/src/js/vendor/pro/jarallax';
import 'mdbootstrap-pro/src/js/vendor/pro/jarallax-video';
import 'mdbootstrap-pro/src/js/vendor/pro/jquery.sticky';
import 'mdbootstrap-pro/src/js/vendor/pro/lightbox';
import 'mdbootstrap-pro/src/js/vendor/pro/ofi';
import 'mdbootstrap-pro/src/js/vendor/pro/picker';
import 'mdbootstrap-pro/src/js/vendor/pro/picker-date';
import 'mdbootstrap-pro/src/js/vendor/pro/picker-date-time';
import 'mdbootstrap-pro/src/js/vendor/pro/picker-time';
import 'mdbootstrap-pro/src/js/vendor/pro/scrollbar';
import 'mdbootstrap-pro/src/js/vendor/pro/scrolling-navbar';
import 'mdbootstrap-pro/src/js/vendor/pro/vector-map';
import 'mdbootstrap-pro/src/js/vendor/pro/vector-map-world-mill';

///@ts-ignore
global.bsCustomFileInput = require('mdbootstrap-pro/src/js/vendor/free/bs-custom-file-input');
///@ts-ignore
window.toastr = require('mdbootstrap-pro/src/js/vendor/pro/toastr');

